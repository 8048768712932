// ContentBox global variables
@import "globals";

// External Libs
@import "vue2-datepicker/index.css";

// .multiselect__tag {
// 	background-color: rgb(41, 116, 146)	!important;
// }

.btn-ortus {
    color: #fff;
    background-color: #8fc73e;
    border-radius: 8px;
}

.btn-ortus:hover {
    color: #8fc73e;
    background-color: #fff;
    border: 1px solid #8fc73e;
}

.bg-gray-opacity {
    background-color:rgba(0,0,0,0.5);
}

// Main Content for Admin Layout
.main-content {
	margin-left: 215px !important;
	.container-fluid{
		padding-left: 15px !important;
		padding-right: 32px !important;
	}
}
.main-content-mini{
	margin-left: 62px !important;
}

// Package view markdown formatting
.panel-package, #legal-stuff {
	h1, h2, h3, h4, h5, h6 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
		font-weight: 600 !important;
		line-height: 1.5 !important;
		color: #32325d !important;
	}

	hr {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
		border-top-width: 3px !important;
	}

	blockquote, pre, code, p {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}

	h1 {
		font-size: 1.625rem !important;
		border-bottom: 1px solid #e9ecef !important;
	}

	h2 {
		font-size: 1.25rem !important;
		border-bottom: 1px solid #e9ecef !important;
	}

	h3 {
		font-size: 1.0625rem !important;
	}

	h4 {
		font-size: 0.9375rem !important;
	}

	h5 {
		font-size: 0.8125rem !important;
	}

	h6 {
		font-size: 0.625rem !important;
	}

	a:not(.nav-link) {
		color: #5e72e4;
	}

	ul:not(.nav-pills) {
		list-style: disc !important;
		margin: 1rem !important;
	}

	li:not(.nav-item) {
		margin-left: 2rem !important;
	}

	ol {
		list-style: auto !important;
	}

}

// Open Source logo
.osi {
    height: 1em;
    vertical-align: middle;
}

.how-help{
	margin: 20px 0px;
}

#package-info{
	margin: 20px 0px;
}

#package-install-info{
	#console-copy{
		padding: 12px;
		margin-bottom: 0px;
		overflow-x: auto;

		code{
			color: #879db3;
			background-color: inherit;
			white-space: nowrap;
		}
	}
}

// My Account
.list-features ul {
    list-style: none;
}

.list-features li {
    margin-bottom: 0.25rem;
	font-size: 0.875rem;
    line-height: 1.25rem;
}

.list-features li:before {
    padding: 0px;
    content: '\f058';
    font-family: FontAwesome;
    margin-right: 1rem;
	color: green;
}

// Invite members
.users-to-invite-placeholder {
	max-height: 12rem !important;
}

// Package Version
.jsoneditor {
	border: thin solid #f8f9fe !important;
}
.jsoneditor-menu{
    background-color: rgb(54, 155, 194) !important;
    border-bottom: 1px solid #f8f9fe !important;
}

.jsoneditor-poweredBy {
	display: none !important;
}

.fileUploadContainter {
	color:rgb(82, 95, 127);
	background-color: gray;
}

// Markdown Anchors
.anchor{
	color: transparent !important;
    font-size: 16px !important;
    margin-left: -10px !important;
}

.anchor:hover{
	padding-right: 4px;
	margin-left: -2px;
	line-height: 1;
	vertical-align: middle;
	font-size: 14px;

	i{
		color: #879db3;
	}
}

// Main + User Layout

.results-heading{
	text-transform: none !important;
}

/* it will create a scroll bar on the tables inside the package description*/
.package-description{
	overflow-x: scroll;
	}

.panel-package{
	margin: 20px;
	padding: 30px;
	background-color: white;
	border: 1px solid transparent;
	border-radius: .375rem;
	box-shadow: 0 1px 1px rgba(0,0,0,.05);
}

/* Handles cropping images inside rendered markdown */
.panel-description {
    img {
        width: 100%;
        object-fit: contain;
    }
}

.panel-filters{
	margin: 20px;
	padding: 30px;
	background-color: white;
	border: 1px solid transparent;
	border-radius: .375rem;
	box-shadow: 0 1px 1px rgba(0,0,0,.05);
}

.package-version-info{
	margin: 15px;
	background-color: white;
	border: 1px solid transparent;
	border-radius: .375rem;
	box-shadow: 0 1px 1px rgba(0,0,0,.05);
}

.panel-generic{
	margin: 15px;
	padding: 20px;
	background-color: white;
	border: 1px solid transparent;
	border-radius: .375rem;
	box-shadow: 0 15px 35px rgba(50,50,93,.1),0 5px 15px rgba(0,0,0,.07)!important;
}

.panel-heading{
	height: 52px;
	margin-top: 30px;
}

.panel-public-package{
	margin: 20px 0px 30px 0px;
	padding: 15px;
	background-color: white;
	border: 1px solid transparent;
	border-radius: .375rem;
	box-shadow: 0 15px 35px rgba(50,50,93,.1),0 5px 15px rgba(0,0,0,.07)!important;
}

.panel-private-package{
	margin: 20px 0px 30px 0px;
	padding: 15px;
	background-color: white;
	border: 3px solid #fb6340;
	border-radius: .375rem;
	box-shadow: 0 15px 35px rgba(50,50,93,.1),0 5px 15px rgba(0,0,0,.07)!important;
}

.package-title{
	border-bottom: 1px solid #eaeaea;
}

.package-summary{
	margin-top: 20px;
}

.package-box-content{
	margin-top: 20px;
}

code {
    color: #c7254e !important;
    border-radius: 4px !important;
}

.list-group-item {
    background-color: white;
}

.margin-top-30{
	margin-top: 30px;
}

#searchBar{
	height: 60px;
	width: 100%;
	background-color: #172b4d;
	padding-bottom: 20px;
}

#clearSearchBtn{
	padding: 0;
}

// Recaptcha
.g-recaptcha {
	display: inline-block;
}

// User page
.user-bg{
	height: 120px;
	background-color: #172b4d
}

.mt--80{
	margin-top: -80px;
}

.panel-package-user{
	margin: 20px;
	padding: 30px;
	background-color: white;
	border: 1px solid #e9ecef!important;
	border-radius: .375rem;
}

.nav-links{
	margin: 0 20px;
}

.navbar-right{
	min-width: 210px;
}

.navbar-nav.loggedInUser{
	height: 50px !important;
}

.modal-backdrop {
    z-index: auto !important;
}
.settings-icon{
	color: rgba(255,255,255,.95);
}
.settings-icon:hover{
	color: rgba(255,255,255,.65);
}
.user-secondary-btn {
	min-width: 300px !important;
}

.user-package-card {
	min-height: 335px !important;
}

.active-filter {
    background: #d2e3ee;
}

.active-filter:hover {
	background: #d2e3ee;
	cursor: not-allowed !important;
}

.group-buttons {
	display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

// Admin
.admin-page-content {
	padding-top: 120px !important;
}
.nav-links-admin{
	min-width: 240px !important;
}
.navbar-vertical .navbar-brand-img, .navbar-vertical .navbar-brand>img {
	height: 4rem !important;
}
.navbar-brand-img-admin {
	height: 4rem !important;
	text-align: center;
}
.card .table td, .card .table th {
    padding-right: 0.5rem !important;
	padding-left: 0.5rem !important;
}

.badge-dot i{
	width: 1rem !important;
    height: 1rem !important;
    margin-right: .375rem;
    border-radius: 50% !important;
}

// Footer
.footer{
	font-size: 1rem !important;
	background-color: #172b4d;
	// sticky footer
	position: absolute;
	width: 100%;
	clear: both;
	/* Set the fixed height of the footer here */
	min-height: 450px;

	.row{
		margin-top: 40px;
	}

	h3{
		font-size: 20px !important;
	    font-weight: 700 !important;
	    line-height: 1.286;
	    color: white;
	    text-align: center;
	    margin-top: 10px;
	    margin-bottom: 10px;
	}

	hr.divider{
		border: 1px solid dark-gray;
   		border-width: 1px 0 0 0;
		box-sizing: content-box;
	    height: 0;
	    color: #eaeaea;
	    margin-top: 21px;
	    margin-bottom: 21px;
	}

	ul{
		font-weight: 400 !important;
		padding-top: 0.107em;
	    padding-bottom: 0.107em;
	    margin-top: 10px;
	    margin-bottom: 10px;
	    font-size: 14px !important;
	}

	li{
		padding-bottom: 10px !important;
	}
	.ortus-logo{
		max-width: 200px;
	}
	.list-unstyled li a {
		font-size: 1rem !important;
	}
}

.level {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.sm-only {
	display: none;
}

.notifications {
	.notification-title {
		font-size : 16px;
	}
	.notification-content{
		font-size : 14px;
	}
}


/** Utilities */
hr{
	border-top-width: 0.0625rem !important;
    border-top-style: solid !important;
    border-top-color: rgb(233, 236, 239) !important;
}
.b-hr{
	border-bottom-width: 0.0625rem !important;
	border-bottom-style: solid !important;
	border-bottom-color: rgb(233, 236, 239) !important;
}
button.close{
	color: black;
}
.border-bottom-white {
    border-bottom: 0.2rem solid white!important;
}
.border-top-white {
    border-top: 0.2rem solid white!important;
}
.border-bottom-gray {
    border-bottom: 0.1rem solid rgba(0, 0, 0, .1)!important;
}
.border-top-gray {
    border-top: 0.1rem solid rgba(0, 0, 0, .1)!important;
}
button.close:hover, button.close:focus{
	@extend button, .close;
}

.breadcrumb {
	background-color: transparent !important;
}

.breadcrumb-item.active {
	color: #32325d !important;
	font-weight: bolder !important;
}

.CodeMirror, .CodeMirror-scroll {
	height: 300px;
}

.alert-dismissible {
    padding-right: 1.5rem !important;
}

[v-cloak] { display: none }

.bg-forgebox {
	background-color: #172b4d;
}

.bg-cfcasts {
	background-color: #369bc2
}

.color-cfcasts {
	color: #369bc2
}

.color-success {
	color: #69deac
}

.color-error {
	color: #fda08b
}

.tw-btn-spinner,
.tw-btn-spinner:after {
	border-radius: 50%;
	width: 1.5em;
	height: 1.5em;
}

.tw-btn-spinner {
	font-size: 10px;
	position: relative;
	text-indent: -9999em;
	border-top: 0.2em solid currentColor;
	border-right: 0.2em solid currentColor;
	border-bottom: 0.2em solid currentColor;
	border-left: 0.2em solid transparent;
	transform: translateZ(0);
	animation: spinning 1s infinite linear;
}

@keyframes spinning {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.mx-datepicker {
	width: 100% !important;
}

.mx-input {
	height: 38px !important;
}

// Flex Utilities
.flex-1 {
    flex: 1 1 0%!important;
}

// Nav bar
.navbar-logo{
	max-width: 50px !important;
}

.navbar-search{
	width: 50%;
}

.navbar .nav-link{
	display: inline-flex;
}

.navbar-search .form-control {
    width: 100px !important;
}

.rememberMeSelect{
	box-shadow: 0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)!important;
}

// Entry
.enterpriseBanner{
	width: 100%;
	margin-top: 0px;
	padding: 30px;
	font-size: 18px;
	border-radius: 0px 0px 2px 2px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	background-color: rgba(167, 167, 167, 0.2);
	text-decoration: none;
	text-align: center;

	p{
		font-size: 18px;
		font-weight: normal;
		line-height: 0px;
		margin: 0px;
	}
}

// Package summary
.package-card-header, .package-card-footer{
	background-color: #f8f9fe !important;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.package-footer{
	padding: 0.25rem 1.5rem !important;
	color: #525f7f !important;
}

.package-summary{
	min-height: 65px !important;
}

.list-group-item-success {
    background-color: #c4f1de !important;
}

.list-group-item-danger {
    background-color: #fcc7d1 !important;
}

.dependency-badge{
	font-size: 16px !important;
	cursor: pointer;
}

.copy-clipboard-placeholder{
	border: 1px #e8e8e8 solid;
    border-left: 6px #e1e1e1 solid;
	letter-spacing: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.btn-icon-clipboard{
	padding-top: 1.5rem !important;
	padding-bottom: 1.5rem !important;
	padding-right: 0.25rem !important;
	padding-left: 0.25rem !important;
}

.btn-icon-clipboard span {
	margin-left: 0px !important;
}

.pointer {
	cursor: pointer;
}

.close-icon {
    height: 46px !important;
}

.action-button:hover {
	transform: scale(1.2);
	opacity: 0.8;
}

.copy-install-string{
	cursor: pointer;
}

.version-table-content {
	font-size: 1rem !important;
	font-weight: 500 !important;
}

.versionsLabel {
	font-size: .65rem !important;
    padding-top: .75rem;
    padding-bottom: .75rem;
    letter-spacing: 1px;
    text-transform: uppercase;
	border-bottom: 1px solid #e9ecef;
	color: #8898aa;
    border-color: #e9ecef;
    background-color: #f6f9fc;
}

.page-item.disabled .page-link {
    background-color: #e0dddd !important;
}

.subscriptions-th{
    height: 134px !important;
}

pre {
	background-color: #fafafa;
	padding: 1em;
}

pre code {
	color: #111 !important;
}

// Modal header fix
.modal-header{
	display: block !important;
}

// Footer
.social-media-icon {
	font-size: x-large;
}

// Alert
.alert-results {
    color: #212529 !important;
    border-color: white;
    background-color: white;
}

.alert-forgebox {
	color: white !important;
    background-color: #172b4d !important;
}

// Subscriptions
.subscription-body-card{
	min-height: 250px;
}
.features-current{
	border-color: lightblue;
	color: rgb(82, 95, 127);
	border-radius: .25rem;
	border-style: solid;
	border-width: 1px;
	font-size: 13px;
	line-height: 1.5;
	padding-top: 10px;
}
.features-upgrade {
	background-color: #e8fdf5;
	border-color: #9eebcf;
	color: #19a974;
	border-radius: .25rem;
	border-style: solid;
	border-width: 1px;
	font-size: 13px;
	line-height: 1.5;
	padding-top: 10px;
}
.features-downgrade {
	background-color: #fff7f6;
	border-color: #fb6340;
	color: red;
	border-radius: .25rem;
	border-style: solid;
	border-width: 1px;
	font-size: 13px;
	line-height: 1.5;
	padding-top: 10px;
}

.member-since-badge {
	font-size: 0.75rem;
}

// Orgs
.teams-card {
	border-radius: 10px;
	background-color: #ced4da;
	height: 70px;
}

.pending-member {
	border: 2px solid red;
}

/** Integrations  **/
.CodeMirror-fullscreen, .editor-toolbar.fullscreen, .editor-preview-active-side{
	z-index : 9999 !important;
}

// Tooltip
.tooltip {
	display: block !important;
	z-index: 10000;
}

.tooltip .tooltip-inner {
	background: black;
	color: white;
	border-radius: 16px;
	padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
	width: 0;
	height: 0;
	border-style: solid;
	position: absolute;
	margin: 5px;
	border-color: black;
	z-index: 1;
}

.tooltip[x-placement^="top"] {
	margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
	border-width: 5px 5px 0 5px;
	border-left-color: transparent !important;
	border-right-color: transparent !important;
	border-bottom-color: transparent !important;
	bottom: -5px;
	left: calc(50% - 5px);
	margin-top: 0;
	margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
	margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
	border-width: 0 5px 5px 5px;
	border-left-color: transparent !important;
	border-right-color: transparent !important;
	border-top-color: transparent !important;
	top: -5px;
	left: calc(50% - 5px);
	margin-top: 0;
	margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
	margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
	border-width: 5px 5px 5px 0;
	border-left-color: transparent !important;
	border-top-color: transparent !important;
	border-bottom-color: transparent !important;
	left: -5px;
	top: calc(50% - 5px);
	margin-left: 0;
	margin-right: 0;
}

.tooltip[x-placement^="left"] {
	margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
	border-width: 5px 0 5px 5px;
	border-top-color: transparent !important;
	border-right-color: transparent !important;
	border-bottom-color: transparent !important;
	right: -5px;
	top: calc(50% - 5px);
	margin-left: 0;
	margin-right: 0;
}

.tooltip.popover .popover-inner {
	background: #f9f9f9;
	color: black;
	padding: 24px;
	border-radius: 5px;
	box-shadow: 0 5px 30px rgba(black, .1);
}

.tooltip.popover .popover-arrow {
	border-color: #f9f9f9;
}

.tooltip[aria-hidden='true'] {
	visibility: hidden;
	opacity: 0;
	transition: opacity .15s, visibility .15s;
}

.tooltip[aria-hidden='false'] {
	visibility: visible;
	opacity: 1;
	transition: opacity .15s;
}

.bg-cfcasts-news {
	background-color: #349CC4;
}

.cfcasts-logo {
	width: 80%;
}

.cfcasts-title {
	color: #fff;
	font-size: 2rem;
}

.cfcasts-text {
	color: #fff;
	font-size: 1.5rem;
}

.btn-cfcasts {
	background-color: #fff;
	color: #349CC4;
	font-weight: bold;
	font-size: 1rem;
}

.btn-cfcasts:hover {
	background-color: rgb(245, 245, 245);
	color: #349CC4;
}

/** Media Queries **/
@media only screen and (min-width : 992px) {
	.modal-lg {
		max-width: 900px !important;
	}
	#sidenav-main{
		max-width: 215px !important;
	}
}
@media only screen and (max-width : 992px) {
	.user-bg{
		height: 220px;
	}
	.navbar-search{
		width: 60%;
	}
	.panel-generic{
		margin: 10px !important;
		padding: 10px !important;
	}
	.nav-links-admin{
		width: 40px !important;
	}
	#sidenav-main{
		max-width: 215px !important;
	}
}
@media only screen and (min-width : 768px) and (max-width : 945px) {
	#sidenav-main{
		max-width: 215px !important;
	}
	.footer {
		position: relative;
		padding-top: 10px;
	}
	.level {
		flex-direction: column;
	}
}
/* Less than ipad */
@media only screen and (max-width : 768px) {
	#sidenav-main{
		max-width: 215px !important;
	}
	.footer {
		position: relative;
		padding-top: 10px;
	}
	.level {
		flex-direction: column;
	}
	.sm-only {
		display: initial;
	}
	.panel-package-user{
		margin: 10px;
		padding: 10px;
	}
	.admin-page-content {
		padding-top: 40px !important;
	}
	.card-profile-actions {
		margin: 2px !important;
		padding: 2px !important;
	}
	.card-lift--hover {
		margin-top: 20px !important;
	}
	.welcome {
		padding: 1rem !important;
	}
}

// Medium devices (tablets, 740px and up)
@media only screen and (min-width : 736px) and (max-width : 1024px) {
	.cfcasts-logo {
		width: 40%;
	}
}