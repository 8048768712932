/*!
 *   Customized variation of node_modules/font-awesome-sass/assets/stylesheets/font-awesome.scss
 *   Ensures that uses the svg font first
!*/
@import "/node_modules/font-awesome-sass/assets/stylesheets/font-awesome/variables";
@import "/node_modules/font-awesome-sass/assets/stylesheets/font-awesome/mixins";

// replaces Font Awesome's path file import. These paths will only work as long as fonts are converted to base64.
@font-face {
  font-family: "FontAwesome";
  src: url("/node_modules/font-awesome-sass/assets/fonts/font-awesome/fontawesome-webfont.svg?v=4.7.0fontawesomeregular");
  src: url("/node_modules/font-awesome-sass/assets/fonts/font-awesome/fontawesome-webfont.svg?v=4.7.0fontawesomeregular")
      format("svg"),
    url("/node_modules/font-awesome-sass/assets/fonts/font-awesome/fontawesome-webfont.woff2?v=4.7.0")
      format("woff2"),
    url("/node_modules/font-awesome-sass/assets/fonts/font-awesome/fontawesome-webfont.woff?v=4.7.0")
      format("woff"),
    url("/node_modules/font-awesome-sass/assets/fonts/font-awesome/fontawesome-webfont.ttf?v=4.7.0")
      format("truetype"),
    url("/node_modules/font-awesome-sass/assets/fonts/font-awesome/fontawesome-webfont.eot?v=4.7.0")
      format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}
@import "/node_modules/font-awesome-sass/assets/stylesheets/font-awesome/core";
@import "/node_modules/font-awesome-sass/assets/stylesheets/font-awesome/larger";
@import "/node_modules/font-awesome-sass/assets/stylesheets/font-awesome/fixed-width";
@import "/node_modules/font-awesome-sass/assets/stylesheets/font-awesome/list";
@import "/node_modules/font-awesome-sass/assets/stylesheets/font-awesome/bordered-pulled";
@import "/node_modules/font-awesome-sass/assets/stylesheets/font-awesome/animated";
@import "/node_modules/font-awesome-sass/assets/stylesheets/font-awesome/rotated-flipped";
@import "/node_modules/font-awesome-sass/assets/stylesheets/font-awesome/stacked";
@import "/node_modules/font-awesome-sass/assets/stylesheets/font-awesome/icons";
@import "/node_modules/font-awesome-sass/assets/stylesheets/font-awesome/screen-reader";